export const getOverviewData = () => {
  return [
    {
      // sectionImage: 'Overview/credit-one-bank-club-logo.png',
      sectionTitle: "Coors Light Landing",
      paragraph:
        "This dynamic open-air concourse offers an unbeatable blend of flexibility and stunning, one-of-a-kind views of the Las Vegas Strip and the football field. Its iconic backdrop and spacious layout make it the perfect setting for any occasion—from keynotes and breakout sessions to luncheons and evening receptions. With unmatched ambiance and adaptability, this venue transforms seamlessly to elevate any event into a memorable experience.",
      area: "24,000",
      reception: "1,200+",
      banquet: "540",
    },
  ];
};

export const getCarouselImages = () => {
  return [
    "CoorsLightLanding/Photos/coors-light-landing-01.jpg",
    "CoorsLightLanding/Photos/coors-light-landing-04.jpg",
    "CoorsLightLanding/Photos/coors-light-landing-02.jpg",
    "CoorsLightLanding/Photos/coors-light-landing-03.jpg",
    "CoorsLightLanding/Photos/coors-light-landing-05.jpg",
  ];
};
export const getEventPhotos = () => {
  return [
    "CoorsLightLanding/EventNew/coors-light-landing-event-1.jpg",
    "CoorsLightLanding/EventNew/coors-light-landing-event-2.jpg",
    "CoorsLightLanding/EventNew/coors-light-landing-event-3.jpg",
    "CoorsLightLanding/EventNew/coors-light-landing-event-4.jpg",
    "CoorsLightLanding/EventNew/coors-light-landing-event-5.jpg",
    "CoorsLightLanding/EventNew/coors-light-landing-event-6.jpg",
    "CoorsLightLanding/EventNew/coors-light-landing-event-7.jpg",
    "CoorsLightLanding/EventNew/coors-light-landing-event-8.jpg",
    "CoorsLightLanding/EventNew/coors-light-landing-event-9.jpg",
  ];
};

export const getFieldViewImages = () => {
  return [
    "CoorsLightLanding/FieldView/VR_Section_201_11_12_Black.jpg",
    "CoorsLightLanding/FieldView/VR_Section_201_11_12_Down.jpg",
    "CoorsLightLanding/FieldView/VR_Section_201_11_12_Front.jpg",
    "CoorsLightLanding/FieldView/VR_Section_201_11_12_Left.jpg",
    "CoorsLightLanding/FieldView/VR_Section_201_11_12_Right.jpg",
    "CoorsLightLanding/FieldView/VR_Section_201_11_12_Up.jpg",
  ];
};

export const getLayoutImages = () => {
  return [
    "CoorsLightLanding/Layout/layout-coors-light-landing-old.jpg",
    "CoorsLightLanding/Layout/layout-coors-light-landing2.jpg",
    // 'CoorsLightLanding/Layout/layout-coors-light-landing.jpg'
  ];
};

export const getLayoutImagesMobile = () => {
  return ["CoorsLightLanding/Layout/layout-coors-light-landing-mbole.png"];
};
